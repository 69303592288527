var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.newScriptFlag
    ? _c("div", { staticClass: "newScript" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "title" }, [_vm._v("新增投稿")]),
          _c("img", {
            staticStyle: { width: "15px", height: "15px", cursor: "pointer" },
            attrs: { src: require("../../assets/images/close.png") },
            on: { click: _vm.close },
          }),
        ]),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-position": "left",
                  "label-width": "100px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "标题", prop: "title" } },
                  [
                    _c("el-input", {
                      attrs: { size: "small", maxlength: "30" },
                      model: {
                        value: _vm.ruleForm.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "title", $$v)
                        },
                        expression: "ruleForm.title",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "投稿类型:", prop: "scriptType" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.ruleForm.scriptType,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "scriptType", $$v)
                          },
                          expression: "ruleForm.scriptType",
                        },
                      },
                      _vm._l(_vm.scriptTypeList, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "文章分类", prop: "category" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "statusClass",
                        attrs: { placeholder: "请选择文章分类", size: "small" },
                        on: { change: _vm.handleCategory },
                        model: {
                          value: _vm.ruleForm.category,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "category", $$v)
                          },
                          expression: "ruleForm.category",
                        },
                      },
                      _vm._l(_vm.categoryList, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "公众号:", prop: "appId" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "statusClass",
                        attrs: { placeholder: "请选择公众号", size: "small" },
                        model: {
                          value: _vm.ruleForm.appId,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "appId", $$v)
                          },
                          expression: "ruleForm.appId",
                        },
                      },
                      _vm._l(_vm.appList, function (item) {
                        return _c("el-option", {
                          key: item.appId,
                          attrs: { label: item.appName, value: item.appId },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "微信用户:", prop: "wxNumber" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          remote: "",
                          "reserve-keyword": "",
                          placeholder: "请输入关键词",
                          "remote-method": _vm.remoteMethod,
                          loading: _vm.loading,
                        },
                        model: {
                          value: _vm.ruleForm.wxNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "wxNumber", $$v)
                          },
                          expression: "ruleForm.wxNumber",
                        },
                      },
                      _vm._l(_vm.wxNameList, function (item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.userName, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "投稿日期:", prop: "contributeDate" } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "date",
                        size: "small",
                        "value-format": "yyyy-MM-dd",
                        placeholder: "选择日期",
                      },
                      model: {
                        value: _vm.ruleForm.contributeDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "contributeDate", $$v)
                        },
                        expression: "ruleForm.contributeDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "" } },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "upload-demo",
                        attrs: {
                          headers: _vm.headers,
                          "before-upload": _vm.beforeUpload,
                          action: _vm.baseURL + "/system/file/uploadFile",
                          data: _vm.uploadParams,
                          limit: 1,
                          "on-success": function (res, file, fileList) {
                            _vm.uploadSuccess(res, file, fileList)
                          },
                          "file-list": _vm.fileList,
                        },
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "small", type: "primary" } },
                          [_vm._v("上传投稿文档")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "comfirn", on: { click: _vm.comfirn } }, [
            _vm._v("确认"),
          ]),
          _c(
            "div",
            {
              staticClass: "comfirn",
              staticStyle: { "margin-left": "20px" },
              on: { click: _vm.close },
            },
            [_vm._v("关闭")]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }