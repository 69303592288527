<template>
  <div v-if="editArticleInfoFlag" class="editArticleInfo">
		<div class="header">
			<div class="title">修改文章信息</div>
			<img @click="close" style="width: 15px;height:15px;cursor: pointer;" src="../../assets/images/close.png" />
		</div>
		<div class="content">
			<el-form style="width:100%" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="笔名" prop="penName">
					<el-input v-model="ruleForm.penName" maxlength="30"></el-input>
				</el-form-item>
				<el-form-item label="文章分类" prop="category">
					<el-select v-model="ruleForm.category" placeholder="请选择文章分类">
						<el-option
							v-for="item in categoryList"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="投稿公众号" prop="appId">
					<el-select v-model="ruleForm.appId" placeholder="请选择投稿公众号">
						<el-option
							v-for="item in appNameList"
							:key="item.appId"
							:label="item.appName"
							:value="item.appId">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</div>
		<div class="footer">
			<div class="comfirnInfo" @click="comfirn">修改</div>
			<div class="cancle" @click="close">取消</div>
		</div>
  </div>
</template>
<script>
import {getSetupConfig, updateArticleInfo} from '../../api/userHome'
export default {
	props: ['editArticleInfoFlag', 'editPenName', 'editCategory', 'editAppId', 'editInfoId'],
  data() {
    return {
			categoryList: [{
				label: '面向药师',
				value: '1'
			},{
				label: '大众科普',
				value: '2'
			},{
				label: '不确定',
				value: '3'
			}],
			appNameList: [],
			ruleForm: {
				penName: '',
				category: '',
				appId: ''
			},
			// rules: {
			// 	penName: [
      //       { required: true, message: '请输入笔名', trigger: 'blur' },
      //     ],
      //   }
    }
  },
	watch: {
		editArticleInfoFlag(newVal) {
			if(newVal) {
				this.getAppList()
				this.ruleForm.penName = this.editPenName
				this.categoryList.forEach(item => {
					if(item.value == this.editCategory) {
						this.ruleForm.category = item.value
					}
				})
				// this.ruleForm.appName = this.editAppName
			}
		}
	},
  mounted() {
  },
  methods: {
		async getAppList() {
			const params = {
				category: this.ruleForm.category
			}
			let res = await getSetupConfig()
			if(res.code == 200) {
				this.appNameList = res.data
				this.ruleForm.appId = this.editAppId
			}
		},
		async comfirn() {
			const params = {
				appId: this.ruleForm.appId,
				category: this.ruleForm.category,
				penName: this.ruleForm.penName,
				id: this.editInfoId,
			}
			let res = await updateArticleInfo(params)
			if(res.code == 200) {
				this.$message({
					message: '修改完成',
					type: 'success'
				});
				this.$emit('confirmEditArticleInfo')
			}
		},
		close() {
			this.$emit('closeEditArticleInfo')
		}
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-select{
	width: 100%;
}
.editArticleInfo {
	width: 500px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px 8px 0px 0px;
	border-radius: 8px 8px 0px 0px;
	background: #FFF;
	display: flex;
	flex-direction: column;
	z-index: 1000;
	.footer {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: center;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.cancle {
			//width: 68px;
			margin-left: 10px;
			cursor: pointer;
			height: 30px;
			border-radius: 4px;
			border: 1px solid #B9B9B9;
			background: #FFF;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 20px;
			box-sizing: border-box;
			line-height: 14px;
			font-size: 14px;
			color: #747474;
		}
		.comfirnInfo {
			//width: 68px;
			cursor: pointer;
			height: 30px;
			border-radius: 4px;
			border: 1px solid #634AC6;
			background: #FFF;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 20px;
			box-sizing: border-box;
			line-height: 14px;
			font-size: 14px;
			color: #634AC6;
		}
	}
	.content {
		padding: 10px 20px;
		flex: 1;
		display: flex;
		.tip {
			line-height: 22px;
			font-size: 14px;
			color: #606266;
		}
	}
	.header {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: space-between;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.title {
			color: #262626;
			font-size: 20px;
			font-weight: bold;
			line-height: 30px;
		}
	}
}
</style>
