<template>
	<div class="handleManuscriptCompenent">
		<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
			<el-menu-item index="1">未发布</el-menu-item>
			<el-menu-item index="9">已发布</el-menu-item>
		</el-menu>
		<div style="display: flex;justify-content: space-between;margin: 16px 0 28px;">
			<el-input style="width: 260px;" @input="inputValue" v-model="searchValue" placeholder="请输入内容查询"></el-input>
			<el-button type="primary" @click="newScriptFlag=true">投稿</el-button>
		</div>
		<div class="tableList" ref="tableList">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
				fit
        stripe
        :height="tableHeight"
        style="width: 100%">
        <el-table-column
          prop="title"
          label="标题">
        </el-table-column>
				<el-table-column
          prop="userName"
          label="用户名">
        </el-table-column>
				<el-table-column
          prop="realName"
          label="真实姓名">
        </el-table-column>
        <el-table-column
          prop="contributeDate"
          label="投稿日期">
        </el-table-column>
        <el-table-column
          prop="publishDate"
          label="发布日期">
        </el-table-column>
				<el-table-column
          prop="appName"
          label="投稿公众号">
        </el-table-column>
				<el-table-column
          prop="contributeStatus"
					v-if="activeIndex == '1'"
          label="状态">
					<template slot-scope="scope">
						<div style="color: #3FA2CC" v-if="scope.row.contributeStatus=='2'">
							{{scope.row.contributeStatusStr}}
						</div>
						<div style="color: #D3AF31" v-else-if="scope.row.contributeStatus=='3'">
							{{scope.row.contributeStatusStr}}
						</div>
						<div style="color: #E55E55" v-else-if="scope.row.status=='4'">
							{{scope.row.contributeStatusStr}}
						</div>
						<div style="color: #3FA2CC" v-else-if="scope.row.status=='6'">
							{{scope.row.contributeStatusStr}}
						</div>
						<div v-else>
							{{scope.row.contributeStatusStr}}
						</div>
					</template>
        </el-table-column>
        <el-table-column
          prop="penName"
          label="笔名">
        </el-table-column>
				<el-table-column
          prop="operate"
					align="center"
					width="400"
          label="操作">
					<template slot-scope="scope">
						<div v-if="activeIndex == '9'">
							<span class="operateItem" @click="editArticleInfo(scope.row)">修改文章信息</span>
							<span class="operateItem" @click="downFile(scope.row)">下载文章</span>
							<span class="operateItem" @click="viewArticle(scope.row.publishLink)">查看</span>
							<span class="operateItem" @click="editArticle(scope.row)">编辑</span>
							<span class="operateItem" @click="handleEditFee(scope.row.id)">稿酬编辑</span>
							<span class="operateItem" @click="changeContributeStatus(scope.row)">{{scope.row.contributeStatus=='11' ? '恢复' : '作废'}}</span>
						</div>
						<div style="margin-right: 20px" v-if="activeIndex == '1' && scope.row.contributeStatus=='11'">
							<span class="operateItem" @click="editArticleInfo(scope.row)">修改文章信息</span>
							<span class="operateItem" @click="changeContributeStatus(scope.row)">恢复</span>
						</div>
						<div v-if="activeIndex == '1' && (scope.row.contributeStatus=='1' || scope.row.contributeStatus=='10')">
							<span class="operateItem" @click="editArticleInfo(scope.row)">修改文章信息</span>
							<span class="operateItem" @click="downFile(scope.row)">下载文章</span>
							<span class="operateItem" @click="receiveManuscript(scope.row.id)">接收</span>
							<span class="operateItem" @click="editOrRejectManuscript(scope.row.id, 'edit')">修回</span>
							<span class="operateItem" @click="editOrRejectManuscript(scope.row.id, 'reject')">拒稿</span>
							<span class="operateItem" @click="changeContributeStatus(scope.row)">作废</span>
						</div>
						<div v-if="activeIndex == '1' && scope.row.contributeStatus=='2'">
							<span class="operateItem" @click="editArticleInfo(scope.row)">修改文章信息</span>
							<span class="operateItem" @click="downFile(scope.row)">下载文章</span>
							<span class="operateItem" @click="changeContributeStatus(scope.row)">作废</span>
						</div>
						<div v-if="activeIndex == '1' && scope.row.contributeStatus=='3'">
							<span class="operateItem" @click="editArticleInfo(scope.row)">修改文章信息</span>
							<span class="operateItem" @click="downFile(scope.row)">下载文章</span>
							<span class="operateItem" @click="viewReason(scope.row.reason, 'edit')">查看修改建议</span>
							<span class="operateItem" @click="changeContributeStatus(scope.row)">作废</span>
						</div>
						<div v-if="activeIndex == '1' && scope.row.contributeStatus=='4'">
							<span class="operateItem" @click="editArticleInfo(scope.row)">修改文章信息</span>
							<span class="operateItem" @click="downFile(scope.row)">下载文章</span>
							<span class="operateItem" @click="viewReason(scope.row.reason, 'reject')">查看拒稿原因</span>
							<span class="operateItem" @click="changeContributeStatus(scope.row)">作废</span>
						</div>
						<div v-if="activeIndex == '1' && scope.row.contributeStatus=='5'">
							<span class="operateItem" @click="editArticleInfo(scope.row)">修改文章信息</span>
							<span class="operateItem" @click="downFile(scope.row)">下载文章</span>
							<span class="operateItem" @click="handleFinishLayout(scope.row.id)">排版完成</span>
							<span class="operateItem" @click="changeContributeStatus(scope.row)">作废</span>
						</div>
						<div v-if="activeIndex == '1' && scope.row.contributeStatus=='6'">
							<span class="operateItem" @click="editArticleInfo(scope.row)">修改文章信息</span>
							<span class="operateItem" @click="downFile(scope.row)">下载文章</span>
							<span class="operateItem" @click="editPreviewLink(scope.row.id, scope.row.previewLink)">修改预览链接</span>
							<span class="operateItem" @click="changeContributeStatus(scope.row)">作废</span>
						</div>
						<div v-if="activeIndex == '1' && scope.row.contributeStatus=='7'">
							<span class="operateItem" @click="editArticleInfo(scope.row)">修改文章信息</span>
							<span class="operateItem" @click="downFile(scope.row)">下载文章</span>
							<span class="operateItem" @click="handleScheduleDate(scope.row.id, scope.row.prePublishDate)">排期</span>
							<span class="operateItem" @click="changeContributeStatus(scope.row)">作废</span>
						</div>
						<div v-if="activeIndex == '1' && scope.row.contributeStatus=='8'">
							<span class="operateItem" @click="editArticleInfo(scope.row)">修改文章信息</span>
							<span class="operateItem" @click="downFile(scope.row)">下载文章</span>
							<span class="operateItem" @click="handleConfirmPublish(scope.row.id)">确认发布</span>
							<span class="operateItem" @click="handleScheduleDate(scope.row.id, scope.row.prePublishDate)">修改排期</span>
							<span class="operateItem" @click="changeContributeStatus(scope.row)">作废</span>
						</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
		<div class="pagination">
      <Pagination :total="total" :limit="pageSize" :currentPage="currentPage" @pagination="pagination"></Pagination>
    </div>
		<userOverlayout v-if="editArticleInfoFlag || editArticleFlag || editFeeFlag || newScriptFlag || rejectOrEditManuscriptFlag||receiveManuscriptFlag||finishLayoutFlag||scheduleDateFlag||publishFlag"></userOverlayout>
		<rejectOrEditManuscript @rejectOrEditManuscriptcomfirn="rejectOrEditManuscriptcomfirn" @rejectOrEditManuscriptClose="rejectOrEditManuscriptFlag=false" :id="rejectOrEditManuscriptId" :rejectOrEditManuscriptFlag="rejectOrEditManuscriptFlag" :contributeStatus="contributeStatus"></rejectOrEditManuscript>
		<receiveManuscript :type="'2'" :receiveManuscriptFlag="receiveManuscriptFlag" :id="receiveManuscriptId" @receiveManuscriptcomfirn="receiveManuscriptcomfirn" @receiveManuscriptClose="receiveManuscriptFlag=false"></receiveManuscript>
		<finishLayout :linkType="linkType" :previewLink="previewLink" :finishLayoutFlag="finishLayoutFlag" :id="finishLayoutId" @finishLayoutconfirm="finishLayoutconfirm" @closeFinishLayout="finishLayoutFlag=false"></finishLayout>
		<scheduleDate :prePublishDate="prePublishDate" :scheduleDateFlag="scheduleDateFlag" :id="scheduleDateId" @scheduleDateconfirm="scheduleDateconfirm" @closeScheduleDate="scheduleDateFlag=false"></scheduleDate>
		<publish :publishFlag="publishFlag" :id="publishId" @publishConfirm="publishConfirm" @closePublish="publishFlag=false"></publish>
		<newScriptComponent :newScriptFlag="newScriptFlag" @comfirnNewScript="comfirnNewScript" @closeNewScript="newScriptFlag=false"></newScriptComponent>
		<editFee :editFeeFlag="editFeeFlag" :editFeeId="editFeeId" @editFeeConfirm="editFeeConfirm" @closeEditFeeFlag="editFeeFlag=false"></editFee>
		<editArticle :editArticleFlag="editArticleFlag" :id="editArticleId" @editArticleConfirm="editArticleConfirm" @closeEditArticle="editArticleFlag=false"></editArticle>
		<editArticleInfo :editArticleInfoFlag="editArticleInfoFlag" :editInfoId="editInfoId" :editPenName="editPenName" :editAppId="editAppId" :editCategory="editCategory" @confirmEditArticleInfo="confirmEditArticleInfo" @closeEditArticleInfo="editArticleInfoFlag=false"></editArticleInfo>
	</div>
</template>
<script>
import {contributeList, downloadFile} from '../../../api/home.js'
import {abandonedScript, recoveryScript} from '../../../api/userHome.js'
import {axiosDownload} from '@/utils/common'
import rejectOrEditManuscript from '../../commonComponent/rejectOrEditManuscript.vue'
import userOverlayout from '../../commonComponent/userOverlayout.vue'
import receiveManuscript from '../../commonComponent/receiveManuscript.vue'
import finishLayout from '../../commonComponent/finishLayout.vue'
import scheduleDate from '../../commonComponent/scheduleDate.vue'
import publish from '../../commonComponent/publish.vue'
import newScriptComponent from '../../commonComponent/newScriptComponent.vue'
import editFee from '../../commonComponent/editFee.vue'
import editArticle from '../../commonComponent/editArticle.vue'
import editArticleInfo from '../../commonComponent/editArticleInfo.vue'
export default {
  name: 'handleManuscriptCompenent',
	components: {
		rejectOrEditManuscript,
		userOverlayout,
		receiveManuscript,
		finishLayout,
		scheduleDate,
		publish,
		newScriptComponent,
		editFee,
		editArticle,
		editArticleInfo
	},
  data() {
    return {
			editInfoId: '',
			editArticleInfoFlag: false,
			editPenName: '',
			editCategory: '',
			editAppId: '',
			tableHeight: 0,
			activeIndex: '1',
			searchValue: '',
			currentPage: 1,
			pageSize: 10,
			total: 0,
			tableData: [],
			loading: false,
			rejectOrEditManuscriptFlag: false,
			contributeStatus: '',
			rejectOrEditManuscriptId: '',
			receiveManuscriptFlag: false,
			receiveManuscriptId: '',
			finishLayoutFlag: false,
			finishLayoutId: '',
			linkType: '1', //1：排版完成，2：更新预发布
			scheduleDateFlag: false,
			scheduleDateId: '',
			publishFlag: false,
			publishId: '',
			previewLink: '',
			prePublishDate: '',
			newScriptFlag: false,
			editFeeFlag: false,
			editFeeId: '',
			editArticleFlag: false,
			editArticleId: ''
    }
  },
  created() {
  },
	mounted() {
		this.getTable()
		window.addEventListener('resize', () => {
			this.tableHeight = 0
			this.$nextTick(() => {
        this.tableHeight = this.$refs.tableList.clientHeight
      })
    })
    this.$nextTick(() => {
      this.tableHeight = this.$refs.tableList.clientHeight
    })
	},
  methods: {
		confirmEditArticleInfo() {
			this.editArticleInfoFlag = false
			this.getTable()
		},
		editArticleInfo(row) {
			this.editInfoId = row.id
			this.editPenName = row.penName
			this.editCategory = row.category
			this.editAppId = row.appId
			this.editArticleInfoFlag = true
		},
		editArticleConfirm() {
			this.editArticleFlag = false
			this.getTable()
		},
		editArticle(row) {
			this.editArticleFlag = true
			this.editArticleId = row.id
		},
		async changeContributeStatus(row) {
			if(row.contributeStatus == '11') {
				let res = await recoveryScript(row.id)
				if(res.code == 200) {
					this.$message({
						message: '完成',
						type: 'success'
					});
					this.getTable()
				}
			} else {
				let res = await abandonedScript(row.id)
				if(res.code == 200) {
					this.$message({
						message: '完成',
						type: 'success'
					});
					this.getTable()
				}
			}
		},
		editFeeConfirm() {
			this.editFeeFlag = false
			this.currentPage = 1
			this.getTable()
		},
		handleEditFee(id) {
			this.editFeeFlag = true
			this.editFeeId = id
		},
		comfirnNewScript() {
			this.newScriptFlag = false
			this.currentPage = 1
			this.getTable()
		},
		async getTable() {
			this.loading = true
			const params = {
				pageNum: this.currentPage,
				pageSize: this.pageSize,
				publishStatus: this.activeIndex,
				searchValue: this.searchValue
			}
			if(this.activeIndex=='1') {
				params.settlementStatus = 0
			}
			let res = await contributeList(params)
			this.loading = false
			if(res.code == 200) {
				this.tableData = res.rows
				this.total = res.total
			}
		},
		publishConfirm() {
			this.publishFlag = false
			this.getTable()
		},
		handleConfirmPublish(id) {
			this.publishFlag = true
			this.publishId = id
		},
		scheduleDateconfirm() {
			this.scheduleDateFlag = false
			this.getTable()
		},
		handleScheduleDate(id,prePublishDate) {
			this.scheduleDateFlag = true
			this.scheduleDateId = id
			this.prePublishDate = prePublishDate
		},
		editPreviewLink(id, previewLink) {
			this.finishLayoutFlag = true
			this.finishLayoutId = id
			this.linkType = '2'
			this.previewLink = previewLink
		},
		finishLayoutconfirm() {
			this.finishLayoutFlag = false
			this.getTable()
		},
		handleFinishLayout(id) {
			this.linkType = '1'
			this.finishLayoutFlag = true
			this.finishLayoutId = id
		},
		inputValue() {
			this.currentPage = 1
			this.getTable()
		},
		handleSelect(val) {
			this.activeIndex = val
			this.currentPage = 1
			this.getTable()
		},
		receiveManuscriptcomfirn() {
			this.receiveManuscriptFlag = false
			// this.currentPage = 1
			this.getTable()
		},
		receiveManuscript(id) {
			this.receiveManuscriptFlag = true
			this.receiveManuscriptId = id
		},
		viewReason(reason, val) {
			let title = val=='reject' ? '拒稿原因' : '修改建议'
			this.$alert(reason, title, {
				confirmButtonText: '确定',
			})
		},
		rejectOrEditManuscriptcomfirn() {
			this.rejectOrEditManuscriptFlag = false
			this.currentPage = 1
			this.getTable()
		},
		editOrRejectManuscript(id, val) {
			this.rejectOrEditManuscriptFlag = true
			this.contributeStatus = val=='reject' ? 4 : 3
			this.rejectOrEditManuscriptId = id
		},
		async downFile(row) {
      let res = await downloadFile(parseInt(row.contributeUrl));
      axiosDownload(row.contributeFileName, res)
    },
		viewArticle(publishLink) {
			window.open(publishLink, '_blank')
		},
		pagination(val) {
      this.pageSize = val.limit
			this.currentPage = val.page
      this.getTable()
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-table__cell {
	padding: 8px 12px !important;
}
::v-deep .el-menu-item.is-active {
	color: #634AC6 !important;
	background: unset !important;
	border-right: unset !important;
}
.handleManuscriptCompenent {
	padding: 36px;
	display: flex;
	flex-direction: column;
	height: 100vh;
	.pagination {
    //margin-top: 20px;
    border-top: unset;
    flex: 0 0 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #EBEEF5;
    border-radius: 0px 0px 8px 8px;
  }
	.tableList {
    flex: 1;
    overflow-y: hidden;
		.operateItem +.operateItem  {
			margin-left: 20px;
		}
		.operateItem {
			font-size: 14px;
			color: #634AC6;
			cursor: pointer;
		}
	}
}
</style>