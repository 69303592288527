<template>
  <div v-if="rejectOrEditManuscriptFlag" class="rejectOrEditManuscript">
		<div class="header">
			<div class="title">{{contributeStatus=='3' ? '修回' : '拒稿'}}</div>
			<img @click="close" style="width: 15px;height:15px;cursor: pointer;" src="../../assets/images/close.png" />
		</div>
		<div class="content">
			<el-input
				style="flex: 1"
				type="textarea"
				placeholder="请输入内容"
				v-model="reason">
			</el-input>
		</div>
		<div class="footer">
			<div class="comfirn" @click="comfirn">确认</div>
		</div>
  </div>
</template>
<script>
import {fallBackManuscript } from '../../api/userHome.js'
export default {
	props: ['contributeStatus', 'rejectOrEditManuscriptFlag', 'id'],
  data() {
    return {
			reason: ''
    }
  },
  created() {

  },
  methods: {
		async comfirn() {
			const params = {
				contributeStatus: parseInt(this.contributeStatus),
				id: parseInt(this.id),
				reason: this.reason
			}
			let res = await fallBackManuscript(params)
			if(res.code == 200) {
				this.$message({
          message: '完成',
          type: 'success'
        });
				this.reason = ''
				this.$emit('rejectOrEditManuscriptcomfirn')
			}
		},
		close() {
			this.reason = ''
			this.$emit('rejectOrEditManuscriptClose')
		}
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-textarea__inner {
	height: 100%
}
.rejectOrEditManuscript {
	height: 320px;
	width: 500px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px 8px 0px 0px;
	border-radius: 8px 8px 0px 0px;
	background: #FFF;
	display: flex;
	flex-direction: column;
	z-index: 1000;
	.footer {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: center;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.comfirn {
			//width: 68px;
			cursor: pointer;
			height: 30px;
			border-radius: 4px;
			border: 1px solid #B9B9B9;
			background: #FFF;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 20px;
			box-sizing: border-box;
			line-height: 14px;
			font-size: 14px;
			color: #747474;
		}
	}
	.content {
		padding: 10px 20px;
		flex: 1;
		display: flex;
	}
	.header {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: space-between;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.title {
			color: #262626;
			font-size: 20px;
			font-weight: bold;
			line-height: 30px;
		}
	}
}
</style>
