/**文档管理 */
import request from '@/utils/request'

// 文档列表数据
export function documentList(data) {
  return request({
    url: '/edt/doc/list',
    method: 'post',
    data: data
  })
}

// 新增文档信息
export function docAdd(data) {
  return request({
    url: '/edt/doc/add',
    method: 'post',
    data: data
  })
}
// 模板搜索-返回前50条
export function templateLimit(data) {
  return request({
    url: '/edt/template/limit',
    method: 'get',
    params: data
  })
}
// 删除素材
export function docDel(id) {
  return request({
    url: '/edt/doc/' + id,
    method: 'DELETE'
  })
}
// 单文件上传
export function uploadFile(data) {
  return request({
    url: '/system/file/uploadFile',
    method: 'post',
    data: data
  })
}
// 查看文档详情
export function editDocument() {
  return request({
    url: 'http://172.16.0.109:3001/mock/47/edit/document/123',
    method: 'get'
  })
}
// 启用-停用
export function auditStatus(id, status) {
  return request({
    url: '/edt/doc/audit/status',
    method: 'put',
    params: {id:id,status:status}
  })
}
// 模板历史版本
export function versionId(id) {
  return request({
    url: '/edt/doc/version/' + id,
    method: 'get'
  })
}
// 获取文档信息
export function docId(id) {
  return request({
    url: '/edt/doc/' + id,
    method: 'get'
  })
}
// 更新文档段落树形菜单
export function updateParagraphTree(data) {
  return request({
    url: '/edt/doc/paragraph/tree',
    method: 'put',
    data: data
  })
}
// 更新文档段落树形菜单
export function updateDocContext(data) {
  return request({
    url: '/edt/doc/context',
    method: 'put',
    data: data
  })
}
// 数据来源列表-返回前50条
export function docSourceLimit(data) {
  return request({
    url: '/edt/doc/source/limit',
    method: 'get',
    params: data
  })
}
// 添加到本账号
export function copyToAccount(data) {
  return request({
    url: '/edt/doc/copyToAccount',
    method: 'get',
    data: data
  })
}
// 查看引用文档
export function refList(data) {
  return request({
    url: '/edt/doc/ref/list',
    method: 'get',
    params: data
  })
}
// 当前版本跟历史版本比较
export function docCompare(data) {
  return request({
    url: '/edt/doc/compare',
    method: 'get',
    params: data
  })
}
// 两个版本文档比较
export function lastDocCompare(data) {
  return request({
    url: '/edt/doc/version/compare',
    method: 'get',
    params: data
  })
}
// 引用文档比较
export function refCompare(data) {
  return request({
    url: '/edt/doc/ref/compare',
    method: 'get',
    params: data
  })
}
// 模板历史信息
export function docHistoryInfo(data) {
  return request({
    url: '/edt/doc/historyInfo',
    method: 'get',
    params: data
  })
}
// 上传新版本
export function newVersion(data) {
  return request({
    url: '/edt/doc/upload/new/version',
    method: 'put',
    params: data
  })
}
// 非机构化文档提交
export function unstructuredSubmit(data) {
  return request({
    url: '/edt/doc/unstructured/submit',
    method: 'put',
    params: data
  })
}
// 文件:获取文件信息
export function fileInfo(id) {
  return request({
    url: '/system/file/info/' + id,
    method: 'get'
  })
}
// 文档参数查看
export function docAtt(data) {
  return request({
    url: '/edt/doc/att',
    method: 'get',
    params: data
  })
}
// 文档参数更新
export function docAttUpdate(data) {
  return request({
    url: '/edt/doc/att',
    method: 'put',
    data: data
  })
}
// 更新文档参考来源
export function docRef(docId,data) {
  return request({
    url: '/edt/doc/ref/' + docId,
    method: 'put',
    data: data
  })
}
// 获取ICD或中药编码-返回前50条
export function dictDrug(data) {
  return request({
    url: '/edt/dict/drug',
    method: 'get',
    params: data
  })
}
// 患者相关-年龄预设查询
export function ageDefg(data) {
  return request({
    url: '/edt/doc/att/age/def',
    method: 'get',
    params: data
  })
}
// 患者相关-年龄预设保存
export function ageDefSave(data) {
  return request({
    url: '/edt/doc/att/age/def',
    method: 'put',
    data: data
  })
}
// 参数-测试相关参数是否符合逻辑 true 为符合 false为不符合
export function attTest(data) {
  return request({
    url: '/edt/doc/att/test',
    method: 'post',
    data: data
  })
}
// 根据相关内容获取表达式的中文描述-传参用 AND OR NOT
export function attExpression(data) {
  return request({
    url: '/edt/doc/att/expression',
    method: 'post',
    data: data
  })
}
// 获取历史版本列表-过滤比version(包含)大的版本
export function filterVersion(id,data) {
  return request({
    url: '/edt/doc/filter/version/' + id,
    method: 'get',
    params: data
  })
}
